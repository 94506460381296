import React from "react";
import { Link } from "react-router-dom";
import "typeahead.js";

const Search = () => {
    const querySearch = () => {
        alert("Disabled");
    };

    return (
        <div className="content-header">
            <div className="content-search">
                <i data-feather="search"></i>
                <input type="search" id="query_value" className="form-control typeahead" placeholder="Nothing to search." autoComplete="off"/>
            </div>
            <nav className="nav">
                <Link to="#" onClick={querySearch.bind()} className="nav-link"><i data-feather="arrow-right-circle"></i></Link>
            </nav>
        </div>
    );
};

export default Search;
