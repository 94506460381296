import { combineReducers } from 'redux';
import authReducer from './auth';
import organizationReducer from './organization';
import searchReducer from './search';

export default combineReducers(Object.assign(
    {auth: authReducer},
    {organization: organizationReducer},
    {search: searchReducer}
));
