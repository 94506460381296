import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './pages/auth/Login';
import setAuthToken from './utils/setAuthToken';
import { logoutUser } from './api/web';
import Private from "./components/routes/Private";
import Logout from "./pages/auth/Logout";
import { Provider } from 'react-redux';
import 'bootstrap';

import store from './stores';

//Check for token
if(localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  const {auth} =  store.getState();

  if(auth.user.user.expiry < new Date()) {
    //Logout user
    store.dispatch(logoutUser());
    //Clear current profile
    window.location.href = "/";
  }
}

class App extends Component {
  render() {
    return (
        <Provider store={ store }>
          <Router>
            <div className="App">
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <Route component={Private} />
              </Switch>
            </div>
          </Router>
        </Provider>
    );
  }
}

export default App;
