import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "organization",
    initialState: {
        riskCategory: [],
        bank: [],
        branch: [],
        accountType: [],
        bodyType: [],
        gariCode: [],
        product: [],
        discount: [],
        loading: []
    },
    reducers: {
        //actions => action handlers
        RISK_CATEGORY: (state, action) => {
            return {
                ...state,
                riskCategory: action.payload
            };
        },
        BANK: (state, action) => {
            return {
                ...state,
                bank: action.payload
            }
        },
        PRODUCT: (state, action) => {
            return {
                ...state,
                product: action.payload
            }
        },
        ACCOUNT_TYPE: (state, action) => {
            return {
                ...state,
                accountType: action.payload
            }
        },
        BODY_TYPE: (state, action) => {
            return {
                ...state,
                bodyType: action.payload
            }
        },
        GARI_CODE: (state, action) => {
            return {
                ...state,
                gariCode: action.payload
            }
        },
        DISCOUNT: (state, action) => {
            return {
                ...state,
                discount: action.payload
            }
        },
        EXTENSION: (state, action) => {
            return {
                ...state,
                extension: action.payload
            }
        }
    }
});

export const {RISK_CATEGORY, BANK, ACCOUNT_TYPE, BODY_TYPE, GARI_CODE, PRODUCT, DISCOUNT, EXTENSION} = slice.actions;
export default slice.reducer;
