import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "search",
    initialState: {
        userId: {}
    },
    reducers: {
        //actions => action handlers
        SEARCH: (state, action) => {
            return {
                ...state,
                userId: action.payload
            };
        }
    }
});

export const {SEARCH} = slice.actions;
export default slice.reducer;
